import React, {ChangeEvent, useState} from 'react';
import ModalWindow from '../modal-window';
import {FormattedMessage, useIntl} from 'react-intl';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import Input from '../input';
import {IReviewDialogue, IReviewType} from '../../redux/review/interfaces';
import GritxSelect from '../gritx-select';

import './style.scss';

interface IReviewModalWindow {
  card: IReviewDialogue
  types: IReviewType[]
  title: string
  onSave: (card: IReviewDialogue) => void
  onClose: () => void
  show: boolean
}

export const ReviewModalWindow = (
  {
    card,
    types,
    title,
    onSave,
    onClose,
    show
  }: IReviewModalWindow
): JSX.Element => {
  const intl = useIntl();
  const [review, setReview] = useState<IReviewDialogue>(card);
  const [typeError, setTypeError] = useState<boolean>(false);

  function handleClose() {
    setReview(card);
    onClose();
  }

  const getValidFormStatus = (): boolean => {
    if (!review.xpeditionId) {
      setTypeError(true);
    } else {
      setTypeError(false);
    }
    return !!review.dialogName && review.dialogName.trim() !== '' && !!review.xpeditionId;
  };

  function handleSave() {
    if (getValidFormStatus()) {
      onSave(review);
    }
  }

  function handleChange(type: string): (e: string) => void {
    return (e) => {
      const newReview = {
        ...review,
        [type]: e
      };

      setReview(newReview);
    };
  }

  function handleSelectType(e: ChangeEvent<HTMLSelectElement>) {
    const newType = types.find(t => t.id.toString() === e.target.value);

    if (newType) {
      setReview({...review, xpeditionId: newType.id});
      setTypeError(false);
    }
  }

  const content = () => {
    return <div className={'review-form__container'}>
      <div className={'form-review'}>
        <div className="form-review__header">
          <h2 className="form-review__title">{title}</h2>
        </div>
        <div className="review-form__body">
          <Input
            required
            type="text"
            value={review.dialogName}
            label={intl.formatMessage({
              id: 'gritx.review.card.name.label',
              defaultMessage: 'Name'
            })}
            onChange={handleChange('dialogName')}
          />
          {!card.dialogId && <div className="review-form__input">
            <span className={'review-form__input__label-text'}>
              <FormattedMessage id={'gritx.review.card.type.label'}
                defaultMessage={'Type'}/>
              <span className={'review-form__input__required'}>*</span>
            </span>
            <GritxSelect
              name={'Review type'}
              options={types}
              placeholder={intl.formatMessage({
                id: 'gritx.review.card.type.placeholder',
                defaultMessage: 'Please, choose the type'
              })}
              defaultValue={'placeholder'}
              onChange={handleSelectType}
              onBlur={handleSelectType}
              className="review-form__selector"
            />
            {
              typeError && <span className="review-form__input__error">
                <FormattedMessage id={'gritx.review.card.type.error'}
                  defaultMessage={'Please, choose the type'}/>
              </span>
            }
          </div>}
        </div>
      </div>
    </div>;
  };

  const formsButtons = () => {
    return <div className="review-form__footer">
      <GritxButton
        title={intl.formatMessage({
          id: 'gritx.common.button.cancel',
          defaultMessage: 'Cancel'
        })}
        variant={ButtonVariant.Light}
        className="review-form__button"
        onClick={handleClose}
      />
      <GritxButton
        title={intl.formatMessage({
          id: 'gritx.common.button.save',
          defaultMessage: 'Save'
        })}
        variant={ButtonVariant.Primary}
        className="review-form__button"
        onClick={handleSave}
      />
    </div>;
  };

  return <ModalWindow
    show={show}
    className="review-form__modal"
    onHide={handleClose}
    footerElement={formsButtons()}
  >
    {content()}
  </ModalWindow>;
};
